import 'antd/dist/antd.compact.css';
import 'tailwindcss/base.css';
import '@civalgo/lego/dist/style.css';
import 'tailwindcss/components.css';
import '../../styles/globals.css';
import 'tailwindcss/utilities.css';

import { Tooltip } from '@civalgo/lego';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ciCommitHash } from '../config';

const AppFrame = dynamic(
  () => import('Components/AppFrame').then((m) => m.AppFrame),
  { ssr: false },
);

export const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });
export default function App({
  Component,
  pageProps,
}: AppProps): React.ReactNode {
  const refCallback = useCallback(
    // We specifically want to log to the console, to debug caching issues
    // eslint-disable-next-line no-console
    () => console.log('Version:', `portal@${ciCommitHash}`),
    [],
  );

  return (
    <main className={inter.className} ref={refCallback}>
      <Tooltip.Provider>
        <DndProvider backend={HTML5Backend}>
          <AppFrame>
            <Component {...pageProps} />
          </AppFrame>
        </DndProvider>
      </Tooltip.Provider>
    </main>
  );
}
